import Vue from "vue";

const language_options = [
  {
    value: "nl_NL",
    key: "Nederlands",
    short: "NL",
  },
  {
    value: "en_US",
    key: "English",
    short: "EN",
  },
  {
    value: "fr_BE",
    key: "Français",
    short: "FR",
  },
];

export default Vue.mixin({
  data: () => ({}),
  methods: {
    getLocaleLabelByCode(code) {
      const localeName = language_options.find(
        (option) => option.value === code
      );
      return localeName ? localeName.key : language_options[0].key;
    },
    getLocales(short = false) {
      if (short) {
        return language_options.map((option) => {
          return {
            value: option.value,
            key: option.short,
          };
        });
      }
      return language_options.map((option) => {
        return {
          value: option.value,
          key: option.key,
        };
      });
    },
  },
});
