<template>
  <div>
    <n-select
      bold
      class="text-sm hidden sm:block"
      :options="getLocales(false)"
      :onchange="switchLanguage"
      :selected="this.currentLanguage"
    />
    <n-select
      bold
      class="text-sm sm:hidden block"
      :options="getLocales(true)"
      :onchange="switchLanguage"
      :selected="this.currentLanguage"
    />
  </div>
</template>

<script>
import i18n from "@/plugins/i18n";
import languageCodes from "@/mixins/languageCodes";

// TODO: replace locales from data with import from mixin
export default {
  mixins: [languageCodes],
  props: {
    onchange
  },
  mounted() {
    console.log(i18n.locale);
  },
  data() {
    return {
      currentLanguage: i18n.locale
    };
  },
  methods: {
    switchLanguage(e) {
      if (this.onchange) {
        // if an onchange prop was passed, use onchange
        this.onchange(e);
        return;
      }
      // else set the language for the entire app
      const lang = e.target.value;
      i18n.locale = lang;
      this.currentLanguage = lang;
    }
  }
};
</script>
