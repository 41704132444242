<template>
  <n-card no-margin class="mt-4">
    <div class="border-b pb-4 font-serif flex">
      <h1 class="font-semibold text-xl">
        <n-html :text="$t('details.personal.forms.communication')" />
      </h1>
      <n-button-link class="ml-auto" @click.native="doEdit(true)" v-if="!edit">
        <n-html :text="$t('details.common.forms.edit')" />
      </n-button-link>
    </div>

    <div class="border-b py-3 leading-8 font-serif">
      <n-form-group
        id="input-group-newsletter"
        :invalid-feedback="$t('form.newsletter.feedback.invalid')"
        :state="form_states.no_newsletter"
      >
        <n-checkbox
          id="input-newsletter"
          v-model="data.no_newsletter"
          :label="$t('form.newsletter.label')"
          :disabled="!edit"
        />
      </n-form-group>
    </div>
    <div class="border-b py-3 leading-8 font-serif">
      <n-form-group
        id="input-group-right-to-object"
        :invalid-feedback="$t('form.right_to_object.feedback.invalid')"
        :state="form_states.right_to_object"
      >
        <n-checkbox
          id="input-right-to-object"
          v-model="data.right_to_object"
          :label="$t('form.right_to_object.label')"
          :disabled="!edit"
        />
      </n-form-group>
    </div>
    <div
      class="flex flex-row justify-between items-center w-full leading-8 pt-4 pb-2"
      v-if="edit"
    >
      <n-button-link variant="cancel" bold @click.native="doEdit(false)">
        <n-html :text="$t('details.common.forms.cancel')" />
      </n-button-link>
      <n-form-group
        class="max-w-max"
        id="button-group-login"
        :invalid-feedback="$t('details.common.forms.error')"
        :state="form_states.form"
      >
        <n-button-regular @click.native="save" :disabled="!valid">
          <n-html :text="$t('details.common.forms.save')" />
        </n-button-regular>
      </n-form-group>
    </div>
  </n-card>
</template>

<script>
import { mapGetters } from "vuex";
import validation from "../../../mixins/validation";

export default {
  name: "components.details.personal.card-communication",
  mixin: [validation],
  data: () => ({
    edit: false,
    data: {
      no_newsletter: true,
      right_to_object: true
    },
    form_states: {
      no_newsletter: null,
      right_to_object: null,
      form: null
    }
  }),
  computed: {
    ...mapGetters({
      locale: "application/getLocale",
      customer: "customer/get"
    }),
    valid() {
      return true;
    }
  },
  methods: {
    doEdit(value) {
      this.edit = value;
    },
    save() {
      this.form_states.form = null;
      console.log(this.data);
      this.$store
        .dispatch("customer/save_communication", {
          id: this.customer.id,
          data: this.data
        })
        .then(() => {
          this.edit = false;
          this.form_states.form = true;
        })
        .catch(() => (this.form_states.form = false));
    }
  },
  created() {
    console.log(this.customer);
    this.data = {
      no_newsletter: this.customer.no_newsletter,
      right_to_object: this.customer.right_to_object
    };
    console.log(this.data);
  }
};
</script>

<style>
.form-mobile-code-icon {
  position: absolute;
  right: 15px;
  top: 2px;
  cursor: pointer;
}
</style>
