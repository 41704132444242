<template>
  <n-card no-margin>
    <h1 class="border-b pb-4 font-serif font-semibold text-xl">
      <n-html :text="$t('details.personal.forms.personal')" />
    </h1>

    <div class="border-b pb-1 pt-3 leading-8 font-serif grid grid-cols-2">
      <div>
        <div class="font-semibold">
          <n-html :text="$t('form.title.label')" />
        </div>
        <div><n-html :text="title" /></div>
      </div>
      <div>
        <div class="font-semibold">
          <n-html :text="$t('form.initials.label')" />
        </div>
        <div>{{ customer.initials }}</div>
      </div>
    </div>
    <div class="border-b pb-1 pt-3 leading-8 font-serif grid grid-cols-2">
      <div>
        <div class="font-semibold">
          <n-html :text="$t('form.first_name.label')" />
        </div>
        <div>{{ customer.first_name || "-" }}</div>
      </div>
      <div>
        <div class="font-semibold">
          <n-html :text="$t('form.middle_name.label')" />
        </div>
        <div>{{ customer.middle_name || "-" }}</div>
      </div>
    </div>
    <div class="border-b pb-1 pt-3 leading-8 font-serif grid grid-cols-2">
      <div>
        <div class="font-semibold">
          <n-html :text="$t('form.last_name.label')" />
        </div>
        <div>{{ customer.surname }}</div>
      </div>
      <div>
        <div class="font-semibold">
          <n-html :text="$t('form.birth_date.label')" />
        </div>
        <div>{{ this.format_date(customer.birth_date, "DD-MM-YYYY") }}</div>
      </div>
    </div>
  </n-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "components.details.personal.card-personal",
  computed: {
    ...mapGetters({
      title: "customer/getTitle",
      customer: "customer/get"
    })
  }
};
</script>
