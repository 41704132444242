<template>
  <n-card no-margin>
    <div class="border-b pb-4 font-serif flex">
      <h1 class="font-semibold text-xl">
        <n-html :text="$t('details.personal.forms.account')" />
      </h1>
    </div>
    <div
      class="pb-1 pt-3 leading-8 font-serif"
      :class="`${!edit ? 'border-b' : ''}`"
    >
      <div class="flex">
        <div class="font-semibold">
          <n-html :text="$t('form.login_email.label')" />
        </div>
        <n-button-link
          class="ml-auto"
          @click.native="doEdit('email', true)"
          v-if="!edit"
        >
          <n-html :text="$t('details.common.forms.edit')" />
        </n-button-link>
      </div>
      <div v-if="!changingEmail">{{ user.email }}</div>
      <div v-else>
        <n-form-group
          id="input-group-email"
          :invalid-feedback="$t('form.login_email.feedback.invalid')"
          :state="form_states.email"
        >
          <n-form-input-default
            id="input-email"
            :placeholder="$t('form.login_email.placeholder')"
            v-model="email"
            type="text"
          />
        </n-form-group>
      </div>
    </div>
    <div
      class="border-b pb-1 pt-3 leading-8 font-serif"
      v-if="emailChangeRequest"
    >
      <div class="font-semibold">
        <n-html :text="$t('form.email_change_request.label')" />
      </div>
      <div>{{ emailChangeRequest.new_email }}</div>
    </div>
    <div class="border-b pb-1 pt-3 leading-8 font-serif">
      <div>
        <div v-if="!changingPassword">
          <div class="flex">
            <div class="font-semibold">
              <n-html :text="$t('form.password.label')" />
            </div>
            <n-button-link
              class="ml-auto"
              @click.native="doEdit('password', true)"
              v-if="!edit"
            >
              <n-html :text="$t('details.common.forms.edit')" />
            </n-button-link>
          </div>
          <div>********</div>
        </div>
        <div v-else>
          <div class="font-semibold">
            <n-html :text="$t('form.password.label_current')" />
          </div>
          <n-form-group
            id="input-group-current-password"
            :invalid-feedback="$t('form.password.feedback.invalid')"
            :state="form_states.current_password"
          >
            <n-form-input-password
              id="input-current-password"
              :placeholder="$t('form.password.placeholder_current')"
              v-model="current_password"
              autocomplete="current-password"
            />
          </n-form-group>
          <p v-show="incorrect_password" class="text-brand-red-500">
            <n-html :text="$t('errors.login.incorrect_password')" />
          </p>
          <p v-show="too_many_requests" class="text-brand-red-500">
            <n-html :text="$t('errors.login.too_many_requests')" />
          </p>
          <div class="font-semibold">
            <n-html :text="$t('form.password.label_new')" />
          </div>
          <n-form-group
            id="input-group-new-password"
            :invalid-feedback="$t('form.password.feedback.invalid')"
            :state="form_states.new_password"
          >
            <n-form-input-password
              id="input-new-password"
              :placeholder="$t('form.password.placeholder_new')"
              v-model="new_password"
              autocomplete="new-password"
            />
          </n-form-group>

          <div class="font-semibold">
            <n-html :text="$t('form.password.label_new_confirm')" />
          </div>
          <n-form-group
            id="input-group-new-password-confirm"
            :invalid-feedback="$t('form.password.feedback.invalid')"
            :state="form_states.new_password_confirm"
          >
            <n-form-input-password
              id="input-new-password-confirm"
              :placeholder="$t('form.password.placeholder_new_confirm')"
              v-model="new_password_confirm"
              autocomplete="new-password"
            />
          </n-form-group>
          <p v-show="no_password_match" class="text-brand-red-500">
            <n-html :text="$t('errors.login.password_match')" />
          </p>
        </div>
      </div>
    </div>
    <div
      class="pb-1 pt-3 leading-8 font-serif"
      :class="`${!edit ? 'border-b' : ''}`"
    >
      <div class="flex">
        <div class="font-semibold">
          <n-html :text="$t('form.language.label')" />
        </div>
        <n-button-link
          class="ml-auto"
          @click.native="doEdit('preferred_locale', true)"
          v-if="!edit"
        >
          <n-html :text="$t('details.common.forms.edit')" />
        </n-button-link>
      </div>
      <div v-if="!changingPreferredLocale">
        {{
          getLocaleLabelByCode(
            this.preferred_locale
              ? this.preferred_locale
              : this.customer.locale_code
          )
        }}
      </div>
      <div v-else>
        <n-form-group id="select-group-preferred-locale">
          <languageSelector :onchange="(locale) => this.changePreferredLocale(locale)"/>
        </n-form-group>
      </div>
    </div>
    <div
      class="flex flex-row justify-between items-center w-full border-t leading-8 pt-4 pb-2"
      v-if="edit"
    >
      <n-button-link variant="cancel" bold @click.native="doEdit('', false)">
        <n-html :text="$t('details.common.forms.cancel')" />
      </n-button-link>
      <n-form-group
        class="max-w-max"
        id="button-group-login"
        :invalid-feedback="$t('details.common.forms.error')"
        :state="form_states.form"
      >
        <n-button-regular @click.native="handleConfirm" :disabled="!valid">
          <n-html :text="$t('details.common.forms.save')" />
        </n-button-regular>
      </n-form-group>
    </div>
  </n-card>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import validation from "../../../mixins/validation";
import languageSelector from "../../language/language-selector.vue";

export default {
  name: "components.details.personal.card-account",
  mixin: [validation],
  components: {
    languageSelector
  },
  data: () => ({
    edit: false,
    changingPassword: false,
    changingEmail: false,
    changingPreferredLocale: false,
    data: {},
    form_states: {
      email: null,
      current_password: null,
      new_password: null,
      new_password_confirm: null,
      form: null
    },
    email: "",
    current_password: "",
    new_password: "",
    new_password_confirm: "",
    incorrect_password: false,
    too_many_requests: false,
    no_password_match: false,
    preferred_locale: ""
  }),
  computed: {
    ...mapGetters({
      mutation: "customer/getMutation",
      customer: "customer/get",
      user: "user/get",
      emailChangeRequest: "user/getEmailChange"
    }),
    valid() {
      return !!(
        this.data &&
        // (this.data.password && this.validatePassword(this.data.password)) &&
        this.email &&
        this.validateEmail(this.email)
      );
    }
  },
  watch: {
    current_password(n) {
      this.form_states.current_password = n && this.validatePassword(n);
    },
    new_password(n) {
      this.form_states.new_password = n && this.validatePassword(n);
    },
    new_password_confirm(n) {
      this.form_states.new_password_confirm = n && this.validatePassword(n);
    },
    email(n) {
      this.form_states.email = n && this.validateEmail(n);
    }
  },
  methods: {
    changePreferredLocale(e) {
      this.preferred_locale = e.target.value;
    },
    validateNewPassword() {
      this.no_password_match = false;
      this.incorrect_password = false;
      this.form_states.current_password =
        this.current_password && this.validatePassword(this.current_password);
      this.form_states.new_password =
        this.new_password && this.validatePassword(this.new_password);
      this.form_states.new_password_confirm =
        this.new_password_confirm &&
        this.validatePassword(this.new_password_confirm);
      if (
        !this.form_states.current_password ||
        !this.form_states.new_password ||
        !this.form_states.new_password_confirm
      ) {
        return false;
      }
      if (this.new_password !== this.new_password_confirm) {
        this.no_password_match = true;
        return false;
      }
      return true;
    },
    changePassword() {
      if (this.validateNewPassword()) {
        const user = this.$firebase.auth().currentUser;
        this.$firebase
          .auth()
          .signInWithEmailAndPassword(user.email, this.current_password)
          .then(() => {
            user.updatePassword(this.new_password).then(() => {
              this.doEdit("", false);
            });
          })
          .catch(error => {
            if (error.code === "auth/wrong-password") {
              this.incorrect_password = true;
            } else if (error.code === "auth/too-many-requests") {
              this.too_many_requests = true;
            }
          });
      }
    },
    doEdit(type, value) {
      if (type === "email") {
        this.changingEmail = value;
      } else if (type === "password") {
        this.changingPassword = value;
      } else if (type === "preferred_locale") {
        this.changingPreferredLocale = value;
      } else {
        value = false;
        this.changingEmail = false;
        this.changingPassword = false;
        this.changingPreferredLocale = false;
      }
      this.edit = value;
    },
    hasChanged(fieldName) {
      return this.user[fieldName] !== this.mutation[fieldName];
    },
    handleConfirm() {
      if (this.changingEmail) {
        this.save();
      } else if (this.changingPassword) {
        this.changePassword();
      } else if (this.changingPreferredLocale) {
        this.changeLocale();
      }
    },
    changeLocale() {
      if (this.preferred_locale !== this.customer.locale) {
        const payload = {
          id: this.customer.id,
          email: this.customer.email,
          streetName: this.customer.street_name,
          houseNo: this.customer.street_number,
          houseNoAddition: this.customer.street_number_addition,
          postCode: this.customer.zip_code,
          city: this.customer.city,
          phoneNo: this.customer.phone,
          birthDate: this.customer.birth_date,
          locale_code: this.preferred_locale ? this.preferred_locale : this.customer.locale_code
        };
        console.log(this.customer);

        this.$store
          .dispatch("customer/save", payload)
          .then(() => {
            this.$store.dispatch("customer/get_mutation");
            this.form_states.form = true;
            this.doEdit("", false);
          })
          .catch(() => {
            this.form_states.form = false;
          });
      } else {
        this.doEdit("", false);
      }
    },
    save() {
      this.form_states.form = null;

      const diff = this.obj_diff(this.user, this.data);

      if (!_.isEmpty(diff)) {
        const payload = {
          customer: this.customer.id,
          old_email: this.user.email,
          new_email: this.data.email,
          locale: this.locale.substring(0, 2)
        };
        console.log(payload.locale);

        this.$store
          .dispatch("user/request_email_change", payload)
          .then(() => {
            this.$store.dispatch("user/get_email_change_request");
            this.form_states.form = true;
            this.doEdit("", false);
          })
          .catch(() => (this.form_states.form = false));
      } else {
        this.doEdit("", false);
      }
    }
  },
  created() {
    this.email = _.clone(this.user.email);
  }
};
</script>

<style>
.form-mobile-code-icon {
  position: absolute;
  right: 15px;
  top: 2px;
  cursor: pointer;
}
</style>
