import Vue from "vue";

export default Vue.mixin({
  methods: {
    validateEmail(email) {
      let re = /[a-zA-Z0-9!#$%&''*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&''*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/;
      return re.test(email);
    },
    validatePassword(password) {
      return password.length >= 8;
    },
    validateNotEmpty(val) {
      return val.trim().length > 0;
    },
    validatePhone(phone) {
      let re = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
      return re.test(phone);
    },
    validateZipCode(code) {
      return code.replace(/ /g, "").length === 6;
    },
    validateInteger(int) {
      return !isNaN(parseInt(int));
    }
  }
});
