import Vue from "vue";

export default Vue.mixin({
  methods: {
    obj_diff(ob1, ob2) {
      return Object.keys(ob2).reduce((diff, key) => {
        if (ob1[key] === ob2[key]) return diff;
        return {
          ...diff,
          [key]: ob2[key]
        };
      }, {});
    },
    obj_empty(obj) {
      return !Object.keys(obj).length;
    }
  }
});
