<template>
  <div
    class="padding-top-grid-between padding-left-grid-between padding-right-grid-between padding-bottom-grid-between"
  >
    <div class="grid grid-cols-12 grid-rows-1 sm:p-8 p-4 sm:gap-8 gap-0">
      <div
        class="col-span-12 sm:mx-4 sm:my-4 mx-0 my-4 sm:mt-6 mt-2 grid grid-cols-2 sm:gap-12 gap-6"
      >
        <h1
          class="col-span-2 lg:col-span-1 uppercase text-brand-purple-500 font-serif font-bold text-2xl sm:text-3xl"
        >
          <n-html :text="$t('details.personal.title')" />
        </h1>
        <div
          class="col-span-2 lg:col-span-1 grid grid-cols-2 font-serif gap-x-6"
        >
          <div class="flex col-span-2 xl:col-span-1">
            <div class="font-semibold mr-3">
              <n-html :text="$t('details.common.header.customer_number')" />:
            </div>
            {{ customer.navision_ext_id }}
          </div>
          <div class="flex col-span-2 xl:col-span-1">
            <div class="font-semibold mr-3">
              <n-html :text="$t('details.common.header.customer_name')" />:
            </div>
            {{ customer.full_name }}
          </div>
        </div>
      </div>
      <div class="col-span-12 xl:col-span-6 sm:mt-0 mt-4">
        <CardPersonal />
        <CardAddress />
      </div>
      <div class="col-span-12 xl:col-span-6 sm:mt-0 mt-4">
        <CardAccount />
        <CardCommunication />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CardPersonal from "./../../components/details/personal/card-personal";
import CardAccount from "./../../components/details/personal/card-account";
import CardAddress from "./../../components/details/personal/card-address";
import CardCommunication from "./../../components/details/personal/card-communication";

export default {
  name: "details.personal",
  components: {
    CardPersonal,
    CardAccount,
    CardAddress,
    CardCommunication
  },
  computed: {
    ...mapGetters({
      customer: "customer/get"
    })
  }
};
</script>

<style>
.details-personal-title {
  display: inline-block;
}
.details-personal-title-side {
  float: right;
  padding-top: 8px;
}
.details-personal-title-side .typography-bold {
  margin-left: 1rem;
  margin-right: 0.5rem;
}
</style>
