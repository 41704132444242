<template>
  <n-card no-margin class="mt-4">
    <div class="border-b pb-4 font-serif flex">
      <h1 class="font-semibold text-xl">
        <n-html :text="$t('details.personal.forms.address')" />
      </h1>
      <n-button-link class="ml-auto" @click.native="doEdit(true)" v-if="!edit">
        <n-html :text="$t('details.common.forms.edit')" />
      </n-button-link>
    </div>
    <div
      class="pb-1 pt-3 leading-8 font-serif grid grid-cols-2"
      :class="`${!edit ? 'border-b' : ''}`"
    >
      <div>
        <div class="font-semibold">
          <n-html :text="$t('form.zip_code.label')" />
        </div>
        <div v-if="!edit">{{ customer.zip_code }}</div>
        <div v-else class="mr-6">
          <n-form-group
            id="input-group-zip_code"
            :invalid-feedback="$t('form.zip_code.feedback.invalid')"
            :state="form_states.zip_code"
          >
            <n-form-input-default
              id="input-zip_code"
              :placeholder="$t('form.zip_code.placeholder')"
              v-model="data.zip_code"
              type="text"
            />
          </n-form-group>
        </div>
      </div>
      <div>
        <div class="font-semibold">
          <n-html :text="$t('form.street_number.label')" />
        </div>
        <div v-if="!edit">{{ customer.street_number }}</div>
        <div v-else>
          <n-form-group
            id="input-group-street_number"
            :invalid-feedback="$t('form.street_number.feedback.invalid')"
            :state="form_states.street_number"
          >
            <n-form-input-default
              id="input-street_number"
              :placeholder="$t('form.street_number.placeholder')"
              v-model="data.street_number"
              type="number"
            />
          </n-form-group>
        </div>
      </div>
    </div>
    <div
      class="pb-1 pt-3 leading-8 font-serif grid grid-cols-1 sm:grid-cols-2"
      :class="`${!edit ? 'border-b' : ''}`"
    >
      <div class="pb-3 sm:pb-0 border-b sm:border-none">
        <div class="font-semibold">
          <n-html :text="$t('form.street_number_addition.label')" />
        </div>
        <div v-if="!edit">{{ customer.street_number_addition || "-" }}</div>
        <div v-else class="mr-6">
          <n-form-group
            id="input-group-street_number_addition"
            :invalid-feedback="
              $t('form.street_number_addition.feedback.invalid')
            "
            :state="form_states.street_number_addition"
          >
            <n-form-input-default
              id="input-street_number_addition"
              :placeholder="$t('form.street_number_addition.placeholder')"
              v-model="data.street_number_addition"
              type="text"
            />
          </n-form-group>
        </div>
      </div>
      <div class="pt-3 sm:pt-0">
        <div class="font-semibold">
          <n-html :text="$t('form.street_name.label')" />
        </div>
        <div v-if="!edit">{{ customer.street_name }}</div>
        <div v-else>
          <n-form-group
            id="input-group-street_name"
            :invalid-feedback="$t('form.street_name.feedback.invalid')"
            :state="form_states.street_name"
          >
            <n-form-input-default
              id="input-street_name"
              :placeholder="$t('form.street_name.placeholder')"
              v-model="data.street_name"
              type="text"
              :disabled="usePostalCodeService"
            />
          </n-form-group>
        </div>
      </div>
    </div>
    <div
      class="pb-1 pt-3 leading-8 font-serif grid grid-cols-2"
      :class="`${!edit ? 'border-b' : ''}`"
    >
      <div>
        <div class="font-semibold">
          <n-html :text="$t('form.city.label')" />
        </div>
        <div v-if="!edit">{{ customer.city }}</div>
        <div v-else class="mr-6">
          <n-form-group
            id="input-group-city"
            :invalid-feedback="$t('form.city.feedback.invalid')"
            :state="form_states.city"
          >
            <n-form-input-default
              id="input-city"
              :placeholder="$t('form.city.placeholder')"
              v-model="data.city"
              type="text"
              :disabled="usePostalCodeService"
            />
          </n-form-group>
        </div>
      </div>
      <div>
        <div class="font-semibold" v-if="edit">
          <n-html :text="$t('form.birth_date.label')" />
        </div>
        <div v-if="edit">
          <n-form-group
            id="input-group-birth_date"
            :invalid-feedback="$t('form.birth_date.feedback.invalid')"
            :state="form_states.birth_date"
          >
            <n-form-input-default
              id="input-birth_date"
              :placeholder="$t('form.birth_date.placeholder')"
              v-model="data.birth_date"
              type="date"
            />
          </n-form-group>
        </div>
      </div>
    </div>
    <div
      class="pb-1 pt-3 leading-8 font-serif grid grid-cols-1 sm:grid-cols-2"
      :class="`${!edit ? 'border-b' : ''}`"
    >
      <div class="pb-3 sm:pb-0 border-b sm:border-none">
        <div class="font-semibold">
          <n-html :text="$t('form.email.label')" />
        </div>
        <div v-if="!edit">{{ customer.email }}</div>
        <div v-else class="mr-6">
          <n-form-group
            id="input-group-email"
            :invalid-feedback="$t('form.email.feedback.invalid')"
            :state="form_states.email"
          >
            <n-form-input-default
              id="input-email"
              :placeholder="$t('form.email.placeholder')"
              v-model="data.email"
              type="text"
            />
          </n-form-group>
        </div>
      </div>
      <div class="pt-3 sm:pt-0">
        <div class="font-semibold">
          <n-html :text="$t('form.phone.label')" />
        </div>
        <div v-if="!edit">{{ customer.phone }}</div>
        <div v-else>
          <n-form-group
            id="input-group-phone"
            :invalid-feedback="$t('form.phone.feedback.invalid')"
            :state="form_states.phone"
          >
            <n-form-input-default
              id="input-phone"
              :placeholder="$t('form.street_name.placeholder')"
              v-model="data.phone"
              type="text"
            />
          </n-form-group>
        </div>
      </div>
    </div>
    <div
      class="flex flex-row justify-between items-center w-full border-t leading-8 mt-4 pt-4 pb-2"
      v-if="edit"
    >
      <n-button-link variant="cancel" bold @click.native="doEdit(false)">
        <n-html :text="$t('details.common.forms.cancel')" />
      </n-button-link>
      <n-form-group
        class="max-w-max"
        id="button-group-login"
        :invalid-feedback="$t('details.common.forms.error')"
        :state="form_states.form"
      >
        <n-button-regular @click.native="save" :disabled="!valid">
          <n-html :text="$t('details.common.forms.save')" />
        </n-button-regular>
      </n-form-group>
    </div>
    <n-alert v-if="success.message" class="mt-4" variant="success">
      <n-html :text="success.message" />
    </n-alert>
    <n-alert v-if="errors.message" class="mt-4" variant="danger">
      <n-html :text="errors.message" />
    </n-alert>
  </n-card>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import validation from "../../../mixins/validation";
import helper from "../../../mixins/helper";

export default {
  name: "components.details.personal.card-address",
  mixin: [validation, helper],
  data: () => ({
    edit: false,
    data: {},
    errors: {
      message: ""
    },
    success: {
      message: ""
    },
    form_states: {
      birth_date: null,
      zip_code: null,
      street_number: null,
      street_number_addition: null,
      street_name: null,
      city: null,
      form: null
    }
  }),
  computed: {
    ...mapGetters({
      customer: "customer/get",
      mutation: "customer/getMutation"
    }),
    valid() {
      return (
        this.data &&
        this.form_states.zip_code &&
        this.form_states.street_number &&
        this.form_states.street_name &&
        this.form_states.city &&
        this.form_states.email &&
        this.form_states.phone
      );
    },
    usePostalCodeService() {
      return this.customer.locale_code === "nl_NL";
    }
  },
  watch: {
    data: {
      handler(n) {
        this.form_states.zip_code = false;
        if (n && this.validateNotEmpty(n.zip_code)) {
          this.form_states.zip_code = true;
          if (this.customer.locale_code === "nl_NL") {
            this.form_states.zip_code = this.validateZipCode(n.zip_code);
          }
        }
        this.form_states.street_number =
          n && this.validateNotEmpty(n.street_number.toString());
        this.form_states.street_name =
          n && this.validateNotEmpty(n.street_name);
        this.form_states.city = n && this.validateNotEmpty(n.city);
        this.form_states.email = n && this.validateEmail(n.email);
        this.form_states.phone = n && this.validatePhone(n.phone);

        if (
          this.customer.zip_code !== n.zip_code ||
          this.customer.street_number !== n.street_number
        )
          this.getAddress();
      },
      deep: true
    },
    edit(n) {
      if (n) {
        this.success.message = "";
        this.errors.message = "";
        this.form_states.form = null;
      }
    }
  },
  methods: {
    doEdit(value) {
      this.edit = value;
    },
    hasChanged(fieldName) {
      return this.customer[fieldName] !== this.mutation[fieldName];
    },
    getAddress() {
      if (!this.usePostalCodeService) return;

      if (!this.form_states.zip_code || !this.form_states.street_number) return;

      const payload = {
        zipCode: this.data.zip_code,
        houseNo: this.data.street_number,
        houseNoAddition: this.data.street_number_addition
      };

      this.$store
        .dispatch("customer/get_address", payload)
        .then(res => {
          this.data.city = res.city;
          this.data.street_name = res.street;
          // this.data.street_number_addition = res.streetAddition;
        })
        .catch(e => {
          console.log(e);
          this.data.city = "";
          this.data.street_name = "";
          // this.data.street_number_addition = "";
        });
    },
    save() {
      this.form_states.form = null;
      this.errors.message = "";
      this.success.message = "";
      const diff = this.obj_diff(this.customer, this.data);
      if (!_.isEmpty(diff)) {
        const payload = {
          id: this.customer.id,
          email: this.data.email,
          streetName: this.data.street_name,
          houseNo: this.data.street_number,
          houseNoAddition: this.data.street_number_addition,
          postCode: this.data.zip_code,
          city: this.data.city,
          phoneNo: this.data.phone,
          birthDate: this.data.birth_date,
          locale_code: this.data.locale_code
        };

        this.$store
          .dispatch("customer/save", payload)
          .then(() => {
            this.$store.dispatch("customer/get_mutation");
            this.edit = false;
            this.form_states.form = true;
            // Show success message
            this.success.message = this.$t(
              "details.customer.change_request.success"
            );
          })
          .catch(e => {
            if (e === "OPEN_REQUEST") {
              this.errors.message = this.$t(
                "details.customer.change_request.open_request"
              );
            } else {
              this.errors.message = this.$t(
                "details.customer.change_request.unknown_error"
              );
            }
            // this.form_states.form = false;
          });
      } else {
        this.edit = false;
      }
    }
  },
  created() {
    this.data = _.clone(this.customer);
  }
};
</script>

<style>
.mutation-pending-icon {
  position: absolute;
  top: 25px;
  right: 20px;
  cursor: pointer;
}
</style>
